import { map } from 'lodash'

//待上架列表
export const waitShelvesCols = [
  {
    prop: 'merge',
    label: '展示图/原型名称',
    slotName: 'mergeSlot',
    minWidth: 200
  },
  {
    prop: 'productCategory',
    label: '原型分类',
    slotName: 'productCategorySlot'
  },
  {
    prop: 'struct',
    label: '款式',
    slotName: 'structSlot',
    minWidth: 200
  },
  {
    prop: 'sex',
    label: '适用人群',
    slotName: 'sexSlot'
  },
  {
    prop: 'season',
    label: '适用季节',
    slotName: 'seasonSlot'
  },
  {
    prop: 'sizeList',
    label: '尺码',
    minWidth: 200,
    slotName: 'sizesSlot'
  },
  {
    prop: 'price',
    label: '价格',
    minWidth: 100,
    slotName: 'priceSlot'
  },
  {
    prop: 'on_time',
    label: '上架日期',
    slotName: 'put_on_timeSlot'
  },
  {
    prop: 'referSize',
    label: '参考尺码',
    slotName: 'base_sizesSlot'
  }
]
//原型列表列表
export const shelvesCols = [
  {
    prop: 'productPrototypePlatform.isNewArrive',
    label: '标签',
    slotName: 'isNewArriveSlot',
    width: 50
  },
  {
    prop: 'merge',
    label: '展示图/原型名称',
    slotName: 'mergeSlot',
    minWidth: 130
  },
  {
    prop: 'productCategory',
    label: '原型分类',
    slotName: 'productCategorySlot',
    minWidth: 80
  },
  {
    prop: 'struct',
    label: '款式',
    slotName: 'structSlot',
    minWidth: 100
  },
  {
    prop: 'sex',
    label: '适用人群',
    slotName: 'sexSlot',
    width: 80
  },
  {
    prop: 'season',
    label: '适用季节',
    slotName: 'seasonSlot',
    width: 80
  },
  {
    prop: 'price',
    label: '价格',
    minWidth: 100,
    slotName: 'priceSlot'
  },
  {
    prop: 'onTime',
    label: '上架日期',
    slotName: 'put_on_timeSlot',
    minWidth: 100
  },
  {
    label: '参考尺码',
    prop: 'referSizeName',
    formatter(row) {
      return $GET(row, 'referSize', {}).sizeName || ''
    }
  },
  {
    prop: 'sizeList',
    label: '尺码',
    minWidth: 200,
    slotName: 'sizesSlot'
  }
  // {
  //   prop: 'referSize',
  //   label: '参考尺码',
  //   slotName: 'base_sizesSlot',
  //   minWidth: 100
  // }
]

//上架
export const basicCols = [
  {
    prop: 'merge',
    label: '展示图/原型名称',
    slotName: 'mergeSlot',
    minWidth: 200
  },
  {
    label: '原型分类',
    slotName: 'orCategorySlot'
  },
  {
    prop: 'styleList',
    label: '款式',
    slotName: 'structSlot',
    minWidth: 200
  },
  {
    prop: 'sex',
    label: '适用人群',
    slotName: 'sexSlot'
  },
  {
    prop: 'season',
    label: '适用季节',
    slotName: 'seasonSlot'
  },
  {
    prop: 'createTime',
    label: '创建日期',
    slotName: 'createTimeSlot'
  },
  {
    prop: 'sizeList',
    label: '尺码',
    minWidth: 200,
    slotName: 'sizesSlot'
  },
  {
    prop: 'sizeList',
    label: '参考尺码',
    slotName: 'originalBasicSlot'
  }
]

//下架
export const unShelvesCols = [
  {
    prop: 'merge',
    label: '展示图/原型名称',
    slotName: 'mergeSlot',
    minWidth: 200
  },
  {
    prop: 'category_name',
    label: '原型分类'
  },
  {
    prop: 'struct',
    label: '款式',
    slotName: 'structSlot',
    minWidth: 200
  },
  {
    prop: 'sex',
    label: '适用人群',
    slotName: 'sexSlot'
  },
  {
    prop: 'season',
    label: '适用季节',
    slotName: 'seasonSlot'
  },
  {
    prop: 'sizes',
    label: '尺码',
    minWidth: 200,
    slotName: 'sizesSlot'
  },
  {
    prop: 'price',
    label: '价格',
    minWidth: 100,
    slotName: 'priceSlot'
  },
  {
    prop: 'off_time',
    label: '下架日期',
    minWidth: 200,
    slotName: 'off_timeSlot'
  }
]

//下架弹窗
export const offShelvesDialogCols = [
  {
    prop: 'chineseName',
    label: '原型名称',
    minWidth: 200,
    slotName: 'nameSlot'
  },
  {
    prop: 'style',
    label: '原型款式',
    slotName: 'prim_structSlot',
    minWidth: 200
  },
  {
    prop: 'size',
    label: '原型尺寸',
    minWidth: 200,
    slotName: 'sizesSlot'
  },
  {
    prop: 'message',
    label: '提示',
    minWidth: 200,
    slotName: 'messageSlot'
  }
]

//刀版图管理
export const knifeMangeCols = [
  {
    prop: 'merge',
    label: '展示图/原型名称',
    slotName: 'mergeSlot',
    minWidth: 200
  },
  {
    prop: 'productCategory',
    label: '原型分类',
    slotName: 'productCategorySlot'
  },
  {
    prop: 'styleList',
    label: '款式',
    slotName: 'structSlot',
    minWidth: 200
  },
  {
    prop: 'sex',
    label: '适用人群',
    slotName: 'sexSlot'
  },
  {
    prop: 'season',
    label: '适用季节',
    slotName: 'seasonSlot'
  },
  {
    prop: 'createTime',
    label: '添加时间',
    slotName: 'createTimeSlot'
  },
  {
    prop: 'sizeList',
    label: '尺码',
    minWidth: 200,
    slotName: 'sizesSlot'
  },
  {
    prop: 'referSize',
    label: '参考尺码',
    slotName: 'base_sizesSlot'
  },
  {
    prop: 'ownerName',
    label: '负责人',
    value: 'manager'
  }
]

//参考尺码管理
export const sizeMangeCols = [
  {
    prop: 'merge',
    label: '展示图/原型名称',
    slotName: 'mergeSlot',
    minWidth: 200
  },
  {
    prop: 'category_name',
    label: '原型分类'
  },
  {
    prop: 'struct',
    label: '款式',
    slotName: 'structSlot',
    minWidth: 200
  },
  {
    prop: 'sizes',
    label: '尺码',
    minWidth: 200,
    slotName: 'sizesSlot'
  },
  {
    prop: 'createTime',
    label: '添加时间',
    slotName: 'createTimeSlot'
  },
  {
    prop: 'base_sizes',
    label: '参考尺码',
    slotName: 'base_sizesSlot'
  }
]

//效果图
export const proShowMangeCols = [
  {
    prop: 'name',
    label: '原型名称'
  },
  {
    prop: 'category_name',
    label: '原型分类',
    slotName: 'proCategorySlot'
  },
  {
    prop: 'styleList',
    label: '图片',
    minWidth: '300',
    slotName: 'pro_prim_structSlot'
  },
  {
    prop: 'referSize',
    label: '参考尺码',
    slotName: 'base_sizesSlot'
  },
  {
    prop: 'ownerName',
    label: '负责人',
    value: 'manager'
  }
]

//设置参考尺码
export const referenceSizeSettingCols = {
  1: [
    {
      prop: 'sizeName',
      label: '尺码',
      align: 'center'
    },
    {
      prop: 'psdPath',
      label: 'psd文件名',
      slotName: 'kl_docsSlot',
      align: 'center'
    },
    {
      prop: 'kl_groups',
      label: 'psd是否有安全线',
      slotName: 'kl_groupsSlot',
      align: 'center'
    }
  ],
  2: [
    {
      prop: 'sizeName',
      label: '尺码',
      align: 'center'
    },
    {
      prop: 'isReferenceSize',
      label: '是否为参考尺码',
      slotName: 'isReferenceSize',
      align: 'center'
    },
    {
      prop: 'select',
      label: '选择参考尺码',
      slotName: 'selectSlot',
      align: 'center'
    }
  ]
}

//价格管理
export const priceManageCols = [
  {
    prop: 'merge',
    label: '展示图/原型名称',
    slotName: 'expandSlot',
    type: 'imgText',
    minWidth: 300
  },
  {
    prop: 'category',
    label: '原型分类',
    slotName: 'orCategorySlot'
  },
  {
    label: '款式',
    slotName: 'structSlot',
    minWidth: 200
  },
  {
    prop: 'sizeList',
    label: '尺码',
    minWidth: 200,
    slotName: 'sizesSlot'
  }
]

//档位分类
export const gearsCategoryCols = [
  {
    label: '档位分类',
    prop: 'name',
    align: 'center',
    slotName: 'expandSlot',
    minWidth: '208px'
  },
  {
    label: '档位数',
    prop: 'gearNum'
  },
  {
    label: '关联原型数',
    prop: 'prodNum'
  },
  {
    label: '添加时间',
    prop: 'createTime',
    slotName: 'gearsCateTimeSlot'
  }
]

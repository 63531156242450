<template>
  <div class="line inline-block" v-if="disableBtn">
    <color-text-btn uiid="zd-up" :loading="loading" type="success" size="mini" class="filter-item" @click="to">
      上架
    </color-text-btn>
    <PutUpShelvesForm
      ref="form"
      title="上架"
      v-if="dialogVisible"
      :showSearch="false"
      :dialogVisible.sync="dialogVisible"
      :sup_this="sup_this"
      :cData="cData"
      :putUpData="putUpData"
    />
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep'
import PutUpShelvesForm from './putUpShelvesForm'
import { formatPutOnData, hasShelvesStyle } from '@/utils'
export default {
  components: { PutUpShelvesForm },
  props: {
    sup_this: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      cData: [],
      putUpData: []
    }
  },
  computed: {
    disableBtn() {
      const data = [cloneDeep(this.data)]
      this.cData = formatPutOnData(data)
      const isHasStyle = hasShelvesStyle(data[0].styleList)
      if (!!isHasStyle.length) {
        return this.cData[0].size.length || this.cData[0].style.length
      } else {
        return this.cData[0].size.length && this.cData[0].style.length
      }
    }
  },
  methods: {
    async to() {
      await this.getData()
      this.dialogVisible = true
    },
    async getData() {
      this.loading = true
      try {
        let data = []
        if (Array.isArray(this.data)) {
          data = this.data
        } else {
          data = [this.data]
        }
        this.putUpData = data.map((item) => {
          const { $sizeList = [], $styleList = [] } = item
          return {
            size: $sizeList?.filter(({ status }) => status == 1),
            style: $styleList?.filter(({ status }) => status == 1)
          }
        })
      } catch (err) {}
      this.loading = false
      return true
    }
  }
}
</script>

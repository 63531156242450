<template>
  <BaseDialog uiid="zd-up-dialog" height="auto" :dialogVisible.sync="dialog" :title="title">
    <div class="recoveryShelvesFormComponent" v-loading="loading">
      <div class="table-wrapper">
        <CommonTable height="auto" :selection="false" :cols="cols" :infoData="data">
          <template #nameSlot="{ scoped, scoped: { index, prop } }">
            <el-checkbox v-model="data[index].status" :uiid="`zd-${index}-${prop}`" @change="proStatusChangeHandler($event, index)">
              {{ scoped[scoped.prop] }}
            </el-checkbox>
          </template>

          <template #prim_structSlot="{ scoped, scoped: { index } }">
            <template v-for="item in scoped[scoped.prop] || []">
              <el-checkbox :key="item.id" v-model="item.status" @change="structChangeHandler($event, index)">{{
                item.name
              }}</el-checkbox>
            </template>

            <div class="put-on-list" v-if="putOnPrim_structs(index).length">
              <div class="label">已上架原型款式:</div>
              <div class="item-list">
                <template v-for="item in putOnPrim_structs(index)">
                  <el-tag size="mini" type="primary" :key="item.id">{{ item.name }}</el-tag>
                </template>
              </div>
            </div>
          </template>

          <template #sizesSlot="{ scoped, scoped: { index } }">
            <template v-for="item in scoped[scoped.prop] || []">
              <el-checkbox :key="item.id" v-model="item.status" @change="sizeChangeHandler($event, index)">{{
                item.sizeName
              }}</el-checkbox>
            </template>

            <div class="put-on-list" v-if="putOnSizes(index).length">
              <div class="label">已上架原型尺码:</div>
              <div class="item-list">
                <template v-for="item in putOnSizes(index)">
                  <el-tag size="mini" type="primary" :key="item.id">{{ item.sizeName }}</el-tag>
                </template>
              </div>
            </div>
          </template>
          <template #messageSlot="{ scoped }">
            <color-text-btn :type="scoped[scoped.prop].status ? 'success' : 'danger'">
              <div class="text">
                {{ scoped[scoped.prop].text }}
              </div>
            </color-text-btn>
          </template>
        </CommonTable>
      </div>
    </div>

    <template #footer>
      <LoadingBtn uiid="zd-up-cancel" @click="cancel"> 取消 </LoadingBtn>
      <el-button uiid="zd-up-confirm" :loading="loading" type="primary" @click="doSubmit" size="small">
        {{ title }}
      </el-button>
    </template>
  </BaseDialog>
</template>
<script>
import { offShelvesDialogCols as cols } from '../cols'
import { commonFromMixin } from '@/mixins'
import { waitToputOn as putOn } from '@/api/product/productApi'

export default {
  mixins: [commonFromMixin],
  props: {
    cData: {
      type: Array,
      required: true
    },
    putUpData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      cols,
      data: this.cData,
      value: '',
      loading: false
    }
  },

  computed: {
    putOnSizes() {
      return (index) => {
        try {
          const { size = [] } = this.putUpData[index]
          if (Array.isArray(size)) {
            return size
          }
          return []
        } catch (err) {
          return []
        }
      }
    },

    putOnPrim_structs() {
      return (index) => {
        try {
          const { style = [] } = this.putUpData[index]
          if (Array.isArray(style)) {
            return style
          }
          return []
        } catch (err) {
          return []
        }
      }
    }
  },
  methods: {
    allProChangeHandler(checked) {
      this.data.map((item) => {
        const { style = [], size = [] } = item
        this.updateDataStatus([[item], style, size], checked)
      })
    },
    proStatusChangeHandler(checked, index) {
      const { style = [], size = [] } = this.data[index]
      this.updateDataStatus([style, size], checked)
      this.validMessage(this.data[index], index)
    },

    structChangeHandler(checked, index) {
      const { style = [], size = [] } = this.data[index]
      if (checked && style.every(({ status }) => status) && size.every(({ status }) => status)) {
        this.updateDataStatus([[this.data[index]]], checked)
      }
      if (!checked) {
        this.updateDataStatus([[this.data[index]]], checked)
      }
      this.validMessage(this.data[index], index)
    },

    sizeChangeHandler(checked, index) {
      const { style = [], size = [] } = this.data[index]
      if (checked && size.every(({ status }) => status) && style.every(({ status }) => status)) {
        this.updateDataStatus([[this.data[index]]], checked)
      }
      if (!checked) {
        this.updateDataStatus([[this.data[index]]], checked)
      }
      this.validMessage(this.data[index], index)
    },

    updateDataStatus(datas, checked) {
      datas.map((data) => {
        data.map((item) => {
          this.$set(item, 'status', checked)
        })
      })
    },
    existPuOn(index = 0) {
      const data = this.putUpData[index]
      const { style = [], size = [] } = data || {}
      return !!(style.length || size.length)
    },
    validMessage(data, index) {
      const existPuOn = this.existPuOn(index)
      const { style = [], size = [] } = data
      const sizesSuccess = size.some(({ status }) => status)
      const structSuccess = style.some(({ status }) => status)
      if (existPuOn) {
        return this.$set(data, 'message', {
          status: true,
          text: ''
        })
      }

      if (sizesSuccess) {
        if (!structSuccess) {
          return this.$set(data, 'message', {
            status: false,
            text: '线上不存在款式，尺码已选择， 款式也必须选择。'
          })
        }
      }

      if (structSuccess) {
        if (!sizesSuccess) {
          return this.$set(data, 'message', {
            status: false,
            text: '线上不存在尺码，款式已选择，尺码也必须选择。'
          })
        }
      }

      return this.$set(data, 'message', {
        status: true,
        text: ''
      })
    },
    async doSubmit() {
      const success = this.data.every(({ message }) => message.status)
      if (!success) {
        return this.$message.warning('请查看提示，存在不合理操作！')
      }
      const params = this.getParams()
      if (!params.sizeIdList.length && this.data.some(({ size }) => size?.length)) {
        return this.$message.warning('请至少选择一个尺码')
      }
      this.doPutUp(params)
    },
    getParams() {
      const prototypeIdList = []
      let sizeIdList = []
      let styleIdList = []
      this.data.map(({ style, size, id, status }) => {
        // if (!this.existPuOn(0)) {
        //   primIds.push(id);
        // }
        prototypeIdList.push(id)
        styleIdList = styleIdList.concat(style?.filter(({ status }) => status).map(({ id }) => id))
        sizeIdList = sizeIdList.concat(size?.filter(({ status }) => status).map(({ id }) => id))
      })
      return {
        prototypeIdList,
        styleIdList,
        sizeIdList
      }
    },
    async doPutUp(params) {
      this.loading = true
      try {
        const { code } = await putOn(params)
        if ($SUC({ code })) {
          this.success()
        }
      } catch (err) {}
      this.loading = false
    },
    success() {
      this.$message.success('操作成功')
      this.init()
      this.$nextTick(() => {
        this.dialog = false
      })
    },
    cancel() {
      this.dialog = false
    }
  },
  created() {
    console.log('this.data', this.data)
  }
}
</script>
<style lang="scss" scoped>
.recoveryShelvesFormComponent {
  display: flex;
  flex-direction: column;
  height: 100%;
  .off-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: flex-end;
    .el-input {
      width: 250px;
    }
  }
  .table-wrapper {
    flex: 1;
    overflow: auto;
    .put-on-list {
      border: 1px solid $border-color;
      padding: 5px;
      margin-top: 10px;
      overflow: hidden;
      .label {
        float: left;
        margin-right: 5px;
      }
      .el-tag {
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
    .el-table {
      border: 1px solid #ebeef5;
      border-right: none;
      ::v-deep {
        td {
          border-right: none;
        }
      }
    }
    .text {
      top: 15px;
      position: absolute;
      width: 80%;
      left: 10%;
      text-align: left;
      font-size: 12px;
      white-space: normal;
    }
  }
}
</style>
